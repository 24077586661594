import { Spinner } from '@chakra-ui/react';

const Loading = () => (
  <div
    style={{
      alignContent: 'center',
      alignItems: 'center',
      justifyItems: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
    }}
  >
    <Spinner
      m={3}
      style={{
        alignContent: 'center',
        alignItems: 'center',
        justifyItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
      }}
    />
  </div>
);

export default Loading;
