import { CLEAR_TOKEN, SET_TOKEN } from './actionTypes';

const initialState = {
  token: localStorage.getItem('token') || null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN: {
      const token = action.payload.token;
      localStorage.setItem('token', token);
      return {
        ...state,
        token,
      };
    }

    case CLEAR_TOKEN: {
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
      };
    }

    default:
      return state;
  }
};

export default reducer;
