import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import {
  Flex,
  Box,
  FormControl,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Image,
} from '@chakra-ui/react';
import { AUTHENTICATE } from '../../api/mutations';
import { setToken } from '../../redux/actions';
import { EmailIcon, LockIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

const LogIn = () => {
  // API
  const [authenticate, { loading, error }] = useMutation(AUTHENTICATE);

  // State
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);
  const [credentials, setCredentials] = React.useState({
    username: '',
    password: '',
  });
  const [changed, setChanged] = React.useState({
    username: false,
    password: false,
  });

  // Handlers
  const handleClick = () => setShow(!show);
  const handleUsernameInput = (e) => {
    setChanged({ ...changed, username: true });
    setCredentials({ ...credentials, username: e.target.value });
  };
  const handlePasswordInput = (e) => {
    setChanged({ ...changed, password: true });
    setCredentials({ ...credentials, password: e.target.value });
  };
  const handleSignIn = async (e) => {
    setChanged({ username: false, password: false });

    try {
      const res = await authenticate({
        variables: credentials,
      });
      const token = res.data.authenticate.accessToken;
      dispatch(setToken(token));
    } catch (e) {}
  };

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Image width={'5em'} alt="BDE Logo" src="/bde.png" />
          <Heading fontSize={'4xl'}>T'es BDE ou pas ? 🧐</Heading>
          <Text fontSize={'lg'} color={'gray.600'}>
            Sign in to your account
          </Text>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}
        >
          <Stack spacing={4}>
            <FormControl id="email">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<EmailIcon color="gray" />}
                />
                <Input
                  isInvalid={error && !changed.username}
                  placeholder="Username or email"
                  type="email"
                  onChange={handleUsernameInput}
                />
              </InputGroup>
            </FormControl>
            <FormControl id="password">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<LockIcon color="gray" />}
                />
                <Input
                  isInvalid={error && !changed.password}
                  placeholder="Password"
                  type={show ? 'text' : 'password'}
                  onChange={handlePasswordInput}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleClick}>
                    {show ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            {error ? (
              <Text align={'center'} color="red.600">
                Invalid username and password combination
              </Text>
            ) : null}
            <Button
              disabled={loading}
              onClick={handleSignIn}
              colorScheme={'blue'}
            >
              Sign In
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

export default LogIn;
