import React from 'react';
import { useToken } from '../../redux/selectors';
import LogIn from './LogIn';
import Dashboard from './Dashboard';

const Admin = () => {
  const token = useToken();

  return token ? <Dashboard /> : <LogIn />;
};

export default Admin;
