import * as React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import ClientProvider from './api/provider';
import StoreProvider from './redux/provider';
import Router from './pages';

export default function App() {
  return (
    <StoreProvider>
      <ClientProvider>
        <ChakraProvider>
          <Router />
        </ChakraProvider>
      </ClientProvider>
    </StoreProvider>
  );
}
