import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import {
  Flex,
  Box,
  Input,
  Stack,
  Text,
  useColorModeValue,
  InputGroup,
  InputLeftElement,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
  Select,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import { GET_ELECTORS, GET_OPTIONS } from '../../api/queries';
import { clearToken } from '../../redux/actions';
import Loading from '../../components/Loading';
import { UPDATE_CHOICE } from '../../api/mutations';
import {
  AddIcon,
  CheckIcon,
  CloseIcon,
  EditIcon,
  SearchIcon,
} from '@chakra-ui/icons';

const Selector = ({ options, onSelect }) => {
  return (
    <Select
      placeholder="Select region"
      onChange={(e) => onSelect(e.target.value)}
    >
      {options.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </Select>
  );
};

const Electors = ({ options, electors }) => {
  const dispatch = useDispatch();
  const [updateChoice] = useMutation(UPDATE_CHOICE);
  const [editing, setEditing] = React.useState(null);
  const [selectedOption, setSelectedOption] = React.useState(null);

  const onEdit = React.useCallback(
    async (userId, optionId) => {
      try {
        await updateChoice({
          variables: { userId, optionId },
        });
      } catch (e) {
        dispatch(clearToken());
      }

      setEditing(null);
    },
    [dispatch, updateChoice, setEditing],
  );

  return electors.map(({ id, firstName, lastName, choice, votedAt }) => {
    const Choice =
      editing === id ? (
        <HStack>
          <Selector options={options} onSelect={setSelectedOption} />
          <IconButton
            onClick={() => onEdit(id, selectedOption)}
            disabled={!selectedOption || selectedOption === choice?.id}
            colorScheme={'green'}
            icon={<CheckIcon />}
          />

          <IconButton
            onClick={() => setEditing(null)}
            colorScheme={'red'}
            icon={<CloseIcon />}
          />
        </HStack>
      ) : choice ? (
        <>
          {choice.name}
          <IconButton
            colorScheme={'gray'}
            disabled={editing}
            onClick={() => setEditing(id)}
            marginLeft={3}
            icon={<EditIcon />}
          />
        </>
      ) : (
        <IconButton
          disabled={editing}
          onClick={() => setEditing(id)}
          icon={<AddIcon />}
        />
      );

    return (
      <Tr key={id}>
        <Td>{lastName}</Td>
        <Td>{firstName}</Td>
        <Td>{Choice}</Td>
        <Td>{votedAt ? new Date(votedAt).toUTCString() : '-'}</Td>
      </Tr>
    );
  });
};

const Panel = ({ options, electors, loading }) => {
  const [nameFilter, setNameFilter] = React.useState('');
  const handleFilter = (e) => setNameFilter(e.target.value);

  if (electors) {
    electors = [...electors].sort((a, b) => {
      const textA = a.lastName.toUpperCase();
      const textB = b.lastName.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    electors = nameFilter
      ? electors.filter(({ firstName, lastName }) => {
          const fullName = firstName + ' ' + lastName;
          return fullName.toLowerCase().includes(nameFilter.toLowerCase());
        })
      : electors;
  }

  return (
    <Box
      rounded={'lg'}
      bg={useColorModeValue('white', 'gray.700')}
      boxShadow={'lg'}
      width={'100%'}
      p={4}
    >
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          children={<SearchIcon color="gray" />}
        />
        <Input
          onChange={handleFilter}
          marginBottom={4}
          placeholder="Filter by name"
        />
      </InputGroup>

      {electors?.length ? (
        <TableContainer w={'100%'}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Last name</Th>
                <Th>First name</Th>
                <Th>Choice</Th>
                <Th>Voted at</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Electors options={options} electors={electors} />
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Text align={'center'} color={'gray.500'}>
          No results found for name: "{nameFilter}"
        </Text>
      )}
    </Box>
  );
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const logOut = React.useCallback(() => dispatch(clearToken()), [dispatch]);
  const { loading: loadingElectors, data: dataElectors } = useQuery(
    GET_ELECTORS,
    { onError: logOut },
  );
  const { loading: loadingOptions, data: dataOptions } = useQuery(GET_OPTIONS, {
    onError: logOut,
  });
  const loading = loadingElectors || loadingOptions;

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack
        width={'100%'}
        align={'center'}
        spacing={8}
        mx={'auto'}
        py={12}
        px={10}
      >
        {loading ? (
          <Loading />
        ) : (
          <Panel
            options={dataOptions?.options}
            electors={dataElectors?.electors}
            loading={true}
          />
        )}
        <Button onClick={logOut} colorScheme={'red'}>
          Log out
        </Button>
      </Stack>
    </Flex>
  );
};

export default Dashboard;
